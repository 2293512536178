<template>
  <div>
    <a-row>
      <div class="title custom-flex">
        <div class="icon" />
        <span>其他数据统计</span>
      </div>
    </a-row>
    <a-row>
      <div class="account-number custom-flex custom-items-center">
        <div>
          <a-icon type="user" :style="{fontSize:'80px',color:'#ffffff'}" />
        </div>
        <div class="content">
          <div>小程序用户数量</div>
          <div class="count">{{ accountsNumber }}</div>
        </div>
      </div>
    </a-row>
    <a-row>
      <div class="valid-packages custom-flex custom-items-center">
        <div>
          <a-icon type="profile" :style="{fontSize:'80px',color:'#ffffff'}" />
        </div>
        <div class="content">
          <div>有效套餐项目数量</div>
          <div class="count">{{ packagesNumber }}</div>
        </div>
      </div>
    </a-row>
    <a-row>
      <div class="valid-product custom-flex custom-items-center">
        <div>
          <a-icon type="profile" :style="{fontSize:'80px',color:'#ffffff'}" />
        </div>
        <div class="content">
          <div>有效产品总数</div>
          <div class="count">{{ productNumber }}</div>
        </div>
      </div>
    </a-row>
  </div>
</template>

<script>
import { findSystemOtherDataStatistics } from '@/api/home'
export default {
  name: 'SystemOtherDataStatistics',
  data() {
    return {
      accountsNumber: 0,
      packagesNumber: 0,
      productNumber: 0
    }
  },
  created() {
    this.fetchData()
  },
  methods: {
    fetchData() {
      findSystemOtherDataStatistics().then(res => {
        if (res.code === 0) {
          this.productNumber = res.data.number_of_product
          this.packagesNumber = res.data.number_of_packages
          this.accountsNumber = res.data.number_of_accounts
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
.title {
  font-size: 14px;

  .icon {
    margin: 3px 3px 0 0;
    width: 3px;
    height: 15px;
    background-color: @primary-color;
  }
}
.account-number{
  border-radius: 10px;
  background: linear-gradient(to top right, #ffa111, #ff9b2e);
  margin: 22px 0 20px 0;
  padding: 20px 0 20px 50px;
}
.valid-packages{
  border-radius: 10px;
  background: linear-gradient(to top right, #26aaf8, #26c8d6);
  padding: 20px 0 20px 50px;
  margin: 0 0 20px 0;
 }
.valid-product{
  border-radius: 10px;
  background: linear-gradient(to top right,#c639ff, #9f5fff);
  padding: 20px 0 20px 50px;
}
.content {
  font-size: 20px;
  color: #ffffff;
  margin-left: 20px;

  .count {
    font-size: 40px;
  }
}

</style>

